body.font-Josefin .font-primary {
    font-family: 'Josefin Sans', sans-serif;
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: 700;
}

body.font-Josefin .page-title-headings h2,
body.font-Josefin .page-title-headings h1 {
    letter-spacing: 0.05em;
}

body.font-Josefin .vehicle-link .font-primary {
    letter-spacing: .02em;
}

body.font-Josefin .site-logo {
    margin-top: 16px;
}

body.font-Josefin .staff-member .staff-photo .staff-name,
body.font-Josefin .staff-member .staff-photo .staff-name span {
    font-weight: 400;
}

body.font-Josefin .header-logo a,
body.font-Josefin .locations-section .dealer-header {
    font-weight: 700;
}



body.font-Josefin .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
    line-height: normal;
}

body.font-Josefin .carousel-featured .specs {
    margin-top: 15px;
}

@media (max-width: 768px) {
    body.font-Josefin .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
        line-height: 1.1em;
    }
}