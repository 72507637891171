/*===================================================================================================
  Contact Us
===================================================================================================*/

.contact-form {
    padding: 20px 0 60px;
    overflow: hidden;
}

.contact-m1 .dealership-photo {
    width: 100%;
    margin-bottom: 25px;
}

.contact-m1 .contact-card {
    padding: 15px;
    margin-bottom: 25px;
}


.contact-m1 .radio-block-label {
    display: block;
}

.contact-m1 .checkbox-inline, .radio-inline {
    padding-left: 25px;
    margin-bottom: 10px;
}

.contact-m1 .dealer-info-card {
    padding: 0 30px;
}

@media screen and (max-width: 991px) {
    .contact-m1 .contact-form {
        margin-top: 25px;
    }

    .contact-m1 .subhead-title {
        margin-top: 0;
    }
}

@media(max-width: 767px) {
    .contact-m1 .wedge {
        display: none;
    }
}


/* Business Hours
===========================================================*/

.contact-m1 .inventory-info {
    padding: 15px 0 5px 0;
}

.contact-m1 .inventory-info .c-table {
    margin-bottom: 8px;
}

.contact-m1 .inventory-info .c-tablecell {
    text-align: right;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
}

.contact-m1 .inventory-info .c-tablecell:first-of-type {
    font-weight: 700;
    text-align: left;
}

/* From Control
===========================================================*/


.contact-m1 label.control-label {
    font-weight: 600;
    font-size: 13px;
}

.contact-m1 .form-group label.required:after {
    content: "*";
    margin-left: 5px;
}

.contact-m1 .form-control { /* TODO: can all of this be removed? it looks like it just duplicates what is already in global-master.css */
    display: block;
    width: 100%;
    height: 50px;
    padding: 6px 12px;
    line-height: 1.42857;
    border: 1px solid;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.contact-m1 .form-control:focus {
        border: 1px solid;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

.contact-m1 .form-control::-moz-placeholder {
        filter: alpha(opacity=100);
        opacity: 1;
    }


.contact-m1 select.form-control {
    position: relative;
    padding-right: 25px;
}

.contact-m1 select::-ms-expand {
    display: none;
}

/* Validation */
.contact-m1 .has-error .form-control {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
}

.contact-m1 .has-error .form-control:focus {
        border-color: #CB3A6C;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
        box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
    }

.contact-m1 .form-control-feedback {
    top: 31px;
}

.contact-m1 .feedback-ok:after {
    font-family: FontAwesome;
    content: "\f00c";
    color: #97a339;
}

.contact-m1 .feedback-error:after {
    font-family: FontAwesome;
    content: "\f0e7";
}

.contact-m1 .help-block {
    display: block;
    margin-top: 0;
    padding: 4px 8px;
    margin-bottom: 10px;
    font-size: 13px;
}

.contact-m1 .has-success .help-block,
.contact-m1 .has-success .radio,
.contact-m1 .has-success .checkbox,
.contact-m1 .has-success .radio-inline,
.contact-m1 .has-success .checkbox-inline,
.contact-m1 .has-success.radio label,
.contact-m1 .has-success.checkbox label,
.contact-m1 .has-success.radio-inline label,
.contact-m1 .has-success.checkbox-inline label,
.contact-m1 .has-success .form-control-feedback,
.contact-m1 .has-success .form-control-feedback {
    color: #97a339;
}

.contact-m1 .has-success .form-control,
.contact-m1 .has-success .form-control:focus {
    border-color: #ccc;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 transparent;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 transparent;
}

.contact-m1 .form-group label.error {
    width: 100%;
    display: block;
    background-color: #cb3a6c;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 10px;
    padding: 4px 8px;
}
