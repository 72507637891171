body.font-Syncopate .font-primary {
    font-family: 'Syncopate', sans-serif;
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: 400;
}

body.font-Syncopate .header-logo h2 {
    font-size: 35px;
}

body.font-Syncopate .header-logo {
    font-size: 35px;
}

body.font-Syncopate .btn {
    line-height: 1.2em;
}

body.font-Syncopate .btn-xlg {
    line-height: 1.4em;
}

body.font-Syncopate .page-title-headings h2,
body.font-Syncopate .page-title-headings h1 {
    letter-spacing: 0.04em;
}

body.font-Syncopate .vehicle-link .font-primary {
    letter-spacing: .02em;
}

body.font-Syncopate .site-logo h2 {
    font-size: 16px;
}

body.font-Syncopate .vehicle-card .vehicle-title {
    font-size: 15px;
}

body.font-Syncopate .vehicle-title {
    font-size: 20px;
    margin-top: 0;
}

body.font-Syncopate .vehicle-price {
    font-size: 16px;
}

body.font-Syncopate .staff-member .staff-photo .staff-name {
    font-size: 13px;
}

body.font-Syncopate .staff-member .staff-photo .staff-name span {
    font-size: 11px;
}

body.font-Syncopate .btn-go {
    font-size: 13px;
}

body.font-Syncopate .special {
    font-size: 13px;
}

body.font-Syncopate .dealer-name {
    font-size: 18px;
}

body.font-Syncopate .locations-section .dealer-header {
    font-size: 16px;
}

body.font-Syncopate .modal-title {
    font-size: 16px;
}

body.font-Syncopate .page-title-headings h2,
body.font-Syncopate .page-title-headings h1,
body.font-Syncopate .section-title h2,
body.font-Syncopate .section-title h1,
body.font-Syncopate .section-title {
    font-size: 24px;
}



body.font-Syncopate .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
    font-size: 44px;
}

@media(max-width: 1200px) {
    body.font-Syncopate .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
        font-size: 32px;
    }
}

@media(max-width:900px) {
    body.font-Syncopate .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
        font-size: 26px;
    }
}

@media screen and (max-width: 768px) {
    body.font-Syncopate .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
        font-size: 23px;
    }
}