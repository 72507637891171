.h-find-m1 .tighter-form {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.h-find-m1 {
    position: relative;
    z-index: 4;
    padding: 20px 0 30px 0;
}

.h-find-m1 .section-title {
    display: none;
}


.h-find-m1 .form-group {
    position: relative;
}

.h-find-m1 label {
    position: absolute;
    top: 5px;
    left: 14px;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .08em;
    z-index: 55001 !important;
}

/* From Control
===========================================================*/

.h-find-m1 label.control-label {
    font-weight: 600;
    font-size: 13px;
    color: #595959;
}

.h-find-m1 .form-group label.required:after {
    content: "*";
    margin-left: 5px;
    color: #B6B6B6;
}


.h-find-m1 .form-control:focus {
    border: 1px solid #c3c3c3;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.h-find-m1 .form-control::-moz-placeholder {
    color: #444;
    filter: alpha(opacity=100);
    opacity: 1;
}

.h-find-m1 .form-control:-ms-input-placeholder {
    color: #444;
}

.h-find-m1 .form-control::-webkit-input-placeholder {
    color: #444;
}

.h-find-m1 select.form-control {
    position: relative;
    color: #444;
    padding-right: 25px;
}

.h-find-m1 select::-ms-expand {
    display: none;
}

/* Validation */
.h-find-m1 .has-error .form-control {
    border-color: #CB3A6C;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
}

.h-find-m1 .has-error .form-control:focus {
    border-color: #CB3A6C;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
}

.h-find-m1 .form-control-feedback {
    top: 31px;
}

.h-find-m1 .feedback-ok:after {
    font-family: FontAwesome;
    content: "\f00c";
    color: #97a339;
}

.h-find-m1 .feedback-error:after {
    font-family: FontAwesome;
    content: "\f0e7";
}

.h-find-m1 .help-block {
    display: block;
    margin-top: 0;
    padding: 4px 8px;
    margin-bottom: 10px;
    font-size: 13px;
}

.h-find-m1 .has-success .help-block,
.h-find-m1 .has-success .radio,
.h-find-m1 .has-success .checkbox,
.h-find-m1 .has-success .radio-inline,
.h-find-m1 .has-success .checkbox-inline,
.h-find-m1 .has-success.radio label,
.h-find-m1 .has-success.checkbox label,
.h-find-m1 .has-success.radio-inline label,
.h-find-m1 .has-success.checkbox-inline label,
.h-find-m1 .has-success .form-control-feedback,
.h-find-m1 .has-success .form-control-feedback {
    color: #97a339;
}

.h-find-m1 .has-success .form-control,
.h-find-m1 .has-success .form-control:focus {
    border-color: #C3C3C3;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 transparent;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 transparent;
}

.h-find-m1 .form-group label.error {
    width: 100%;
    display: block;
    background-color: #cb3a6c;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 10px;
    padding: 4px 8px;
}

@media (max-width: 767px) {
    .h-find-m1 .section-title {
        margin-top: -7px;
        display: block;
        text-align: center;
    }

    .h-find-m1 .section-heading {
        padding-bottom: 10px;
    }

    .h-find-m1 {
        padding: 20px 0 40px 0;
    }


    .h-find-m1 > .section-heading > h2 {
        padding: 15px 15px;
    }
}
