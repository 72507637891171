body.font-Roboto .font-primary {
	font-family: 'Roboto Slab', serif;
	text-transform: none;
	letter-spacing: .06em;
	font-weight: 400;
}
body.font-Roboto .page-title-headings h2,
body.font-Roboto .page-title-headings h1 {
    letter-spacing: 0.06em;
}

body.font-Roboto .btn {
	text-transform: none;
}


