/* Breadcrumb */
.inventory-m1 .breadcrumb {
    background: transparent;
    margin-bottom: 0;
    padding: 0 0 10px 0;
}

/* From Control */
.inventory-m1 label.control-label {
    font-weight: 600;
    font-size: 13px;
}

.inventory-m1 .form-group label.required:after {
    content: "*";
    margin-left: 5px;
}

.inventory-m1 .form-control:focus {
    border: 1px solid;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.inventory-m1 .form-control::-moz-placeholder {
    filter: alpha(opacity=100);
    opacity: 1;
}

.inventory-m1 select.form-control {
    position: relative;
    padding-right: 25px;
}

.inventory-m1 select::-ms-expand {
    display: none;
}

/* Validation */
.inventory-m1 .has-error .form-control {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
}

.inventory-m1 .has-error .form-control:focus {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
}

.inventory-m1 .form-control-feedback {
    top: 31px;
}

.inventory-m1 .feedback-ok:after {
    font-family: FontAwesome;
    content: "\f00c";
}

.inventory-m1 .feedback-error:after {
    font-family: FontAwesome;
    content: "\f0e7";
}

.inventory-m1 .help-block {
    display: block;
    margin-top: 0;
    padding: 4px 8px;
    margin-bottom: 10px;
    font-size: 13px;
}

.inventory-m1 .has-success .form-control,
.inventory-m1 .has-success .form-control:focus {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 transparent;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 transparent;
}

.inventory-m1 .form-group label.error {
    width: 100%;
    display: block;
    font-size: 13px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 10px;
    padding: 4px 8px;
}

.inventory-m1 .to {
    display: inline-block;
    float: left;
    font-size: 11px;
    padding-top: 10px;
    text-align: center;
    width: 6%;
}