/*===================================================================================================
  Footer
===================================================================================================*/
.footer-m1 {
    padding: 0;
}

/* Navigation Links
===========================================================*/
.footer-m1 .footer-links {
    padding: 20px 0 0;
    text-align: center;
}

.footer-m1 .footer-links li {
    padding-right: 10px;
    padding-left: 10px;
}

.footer-m1 .footer-links ul > li > a,
.footer-m1 .footer-links p {
    text-transform: uppercase;
    letter-spacing: .04em;
}

.footer-m1 .footer-links ul > li > a:hover {
    text-decoration: none;
}

.footer-links-block .dropdown-item {
    display: block;
    width: 100%;
    padding: 10px 15px;
}

@media(max-width: 500px) {
    .footer-m1 .footer-links-block li {
        display: block;
        margin-bottom: 10px;
    }
}

/* Social Links
===========================================================*/
.footer-m1 .social-links a {
    font-size: 23px;
}

.footer-m1 .social-links {
    margin-top: 5px;
    margin-bottom: 20px;
}


/* Copyright
===========================================================*/

.footer-m1 .copyright-section {
    padding: 15px 0;
    font-size: 10px;
    letter-spacing: 0.7px;
}

.footer-m1 .copyright-section p {
    font-size: 11px;
}

.footer-m1 .copyright-section hr {
    margin-top: 0;
    margin-bottom: 20px;
    border-width: 1px 0 0;
    border-style: solid none none;
    border-color: #d9d9d9 -moz-use-text-color -moz-use-text-color;
    -moz-border-top-colors: none;
    -moz-border-right-colors: none;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    border-image: none;
    opacity: .2;
}

.footer-m1 ul.footer-nav {
    text-align: right;
}

.footer-m1 ul.footer-nav li {
    margin-left: 15px;
}

.footer-m1 ul.footer-nav li:first-child {
    margin-left: 0;
}

.footer-m1 ul.footer-nav li a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}


@media (max-width: 991px) {
    .footer-m1 .copyright-section {
        text-align: center;
    }

    .footer-m1 ul.footer-nav {
        display: block;
        text-align: center;
        margin-top: 10px;
        padding-left: 0;
    }
}
