
.h-inventory-carousel-m1 .section-title {
    text-align: center;
}


/* Featured
===========================================================*/
.h-inventory-carousel-m1 .data-carouselshowmanymoveone {
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .h-inventory-carousel-m1 .data-carouselshowmanymoveone {
        margin-bottom: 0;
    }
}


.h-inventory-carousel-m1 .carousel-inner {
    width: 100%;
    overflow: hidden;
    position: relative;
    clear: both;
}

.h-inventory-carousel-m1 .carousel-control {
    width: 4%;
    opacity: 1;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.h-inventory-carousel-m1 .carousel-control.left,
.h-inventory-carousel-m1 .carousel-control.right {
    margin-left: 0;
    margin-right: 0;
    background-image: none;
}

.h-inventory-carousel-m1 .carousel-control .glyphicon-chevron-left,
.h-inventory-carousel-m1 .carousel-control .glyphicon-chevron-right,
.h-inventory-carousel-m1 .carousel-control .icon-next,
.h-inventory-carousel-m1 .carousel-control .icon-prev {
    width: 60px;
    height: 60px;
    margin-top: -15px;
    font-size: 30px;
    text-align: center;
    vertical-align: middle;
    line-height: 58px;
    background-color: rgba(255,255,255, 0.5);
    z-index: 48000;
}

@media(max-width: 768px) {
    .h-inventory-carousel-m1 .carousel-control .glyphicon-chevron-left,
    .h-inventory-carousel-m1 .carousel-control .glyphicon-chevron-right,
    .h-inventory-carousel-m1 .carousel-control .icon-next,
    .h-inventory-carousel-m1 .carousel-control .icon-prev {
        top: 81px;
        width: 45px;
        height: 45px;
        font-size: 25px;
        line-height: 45px;
    }
}

.h-inventory-carousel-m1 .extra1,
.h-inventory-carousel-m1 .extra2,
.h-inventory-carousel-m1 .extra3 {
    display: none;
}

@media (max-width: 1492px) and (min-width: 992px) {
    .h-inventory-carousel-m1 .vehicle-link {
        padding: 8px 5px;
    }

    .h-inventory-carousel-m1 .vehicle-title {
        font-size: 17px;
    }

    .h-inventory-carousel-m1 .vehicle-price {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .h-inventory-carousel-m1 .vehicle-description {
        display: none;
    }

    .h-inventory-carousel-m1 .vehicle-miles {
        padding-top: 0;
        text-align: left;
    }

    .h-inventory-carousel-m1 .vehicle-price-miles [class*='col-'] {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    .h-inventory-carousel-m1 .active.left {
        left: -50%;
    }

    .h-inventory-carousel-m1 .active.right {
        left: 50%;
    }

    .h-inventory-carousel-m1 .next {
        left: 50%;
    }

    .h-inventory-carousel-m1 .prev {
        left: -50%;
    }

    .h-inventory-carousel-m1 .extra1 {
        display: block;
    }
}

@media only screen and (min-width: 768px) and (-webkit-transform-3d) {
    .h-inventory-carousel-m1 .item.active.left,
    .h-inventory-carousel-m1 .item.prev {
        transform: translate3d(-50%, 0, 0);
        left: 0;
    }

    .h-inventory-carousel-m1 .item.active.right,
    .h-inventory-carousel-m1 .item.next {
        transform: translate3d(50%, 0, 0);
        left: 0;
    }
}


@media only screen and (min-width: 992px) {
    .h-inventory-carousel-m1 .active.left {
        left: -25%;
    }

    .h-inventory-carousel-m1 .active.right {
        left: 25%;
    }

    .h-inventory-carousel-m1 .next {
        left: 25%;
    }

    .h-inventory-carousel-m1 .prev {
        left: -25%;
    }

    .h-inventory-carousel-m1 .extra2,
    .h-inventory-carousel-m1 .extra3 {
        display: block;
    }
}

@media only screen and (min-width: 992px) and (-webkit-transform-3d) {
    .h-inventory-carousel-m1 .item.active.left,
    .h-inventory-carousel-m1 .item.prev {
        transform: translate3d(-25%, 0, 0);
        left: 0;
    }

    .h-inventory-carousel-m1 .item.active.right,
    .h-inventory-carousel-m1 .item.next {
        transform: translate3d(25%, 0, 0);
        left: 0;
    }
}


/* Homepage Vehicle Cards
===========================================================*/


.h-inventory-carousel-m1 .vehicle-card {
    position: relative;
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .h-inventory-carousel-m1 .vehicle-card {
        margin-bottom: 15px;
    }
}


.h-inventory-carousel-m1 .vehicle-card .vehicle-photo {
    position: relative;
	height: 190px;
	overflow-y: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.h-inventory-carousel-m1 .vehicle-card .vehicle-price {
    /*position: absolute;*/
    bottom: 12px;
    right: 10px;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 14px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
}

.h-inventory-carousel-m1 .vehicle-card .vehicle-price span.special {
    /*position: absolute;*/
    top: 100%;
    right: 0;
    font-size: 13px;
    font-weight: 600;
    padding: 2px 10px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
}

.h-inventory-carousel-m1 .vehicle-card .vehicle-price span.data-email-for-price {
    cursor: pointer;
}

.h-inventory-carousel-m1 .vehicle-card .vehicle-info {
    padding: 15px;
    border-top: 3px solid;
    border-left: 0;
    border-right: 0;
}

.h-inventory-carousel-m1 .vehicle-card .vehicle-info .vehicle-phone {
    font-size: 16px;
    font-weight: 200;
}


.h-inventory-carousel-m1 .vehicle-card .vehicle-photo img {
    width: 100%;
    border-bottom: 0 solid;
}

.h-inventory-carousel-m1 .vehicle-card .vehicle-title {
    font-size: 18px;
    margin-top: 10px;
}

.h-inventory-carousel-m1 .vehicle-card .vehicle-miles {
    font-size: 15px;
    text-align: left;
    margin-top: 0;
    padding-top: 0;
}


.h-inventory-carousel-m1 .no-vehicles-found {
    width: 500px;
    max-width: 100%;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-align: center;
    border-radius: 3px;
}
