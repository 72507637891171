body.font-Fjalla .font-primary {
    font-family: 'Fjalla One', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: .03em;
}

body.font-Fjalla .vehicle-link .font-primary {
    letter-spacing: .03em;
}

body.font-Fjalla .btn {
    font-size: 16px;
}

body.font-Fjalla .btn-xl {
    font-size: 16px;
}

body.font-Fjalla .btn-xlg {
    font-size: 18px;
}

body.font-Fjalla .btn-super {
    font-size: 17px;
}

body.font-Fjalla .btn-sm {
    font-size: 15px;
}

body.font-Fjalla .staff-member .staff-photo .staff-name {
    font-size: 16px;
}

body.font-Fjalla .section-title,
body.font-Fjalla .page-title-headings h2,
body.font-Fjalla .page-title-headings h1,
body.font-Fjalla .locations-section .dealer-header,
body.font-Fjalla .vehicle-title,
body.font-Fjalla .vehicle-card .vehicle-title,
body.font-Fjalla .site-logo h2,
body.font-Fjalla .hero-overlay h2,
body.font-Fjalla .section-title h2,
body.font-Fjalla .dealer-name,
body.font-Fjalla .modal-title,
body.font-Fjalla .staff-member .staff-photo .staff-name,
body.font-Fjalla .staff-member .staff-photo .staff-name span,
body.font-Fjalla .header-logo a {
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-weight: 400 !important;
}


body.font-Fjalla .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
    line-height: 1em;
}

body.font-Fjalla .carousel-featured .specs {
    margin-top: 10px;
}

@media (max-width: 768px) {
    body.font-Fjalla .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
        line-height: 1.1em;
    }
}