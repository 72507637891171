/*===================================================================================================
  Global Features
===================================================================================================*/
body {
    font-weight: 400;
    position: relative;
}
/* Links & Buttons
===========================================================*/

a {
    color: #262626;
    text-decoration: none;
    font-weight: 400;
}

    a:hover {
        color: #0083ae;
    }


.btn {
    letter-spacing: .04em;
    font-weight: 700;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.btn:hover {
    color: #fff;
}

.btn-default,
.btn-default:focus {
    background-color: #2f3542;
    color: #fff;
    border-color: #2f3542;
}

.btn-default:hover,
.btn-default:active,
.btn-default.active {
    background-color: #85868C;
    color: #fff;
    border-color: #85868C;
}

.btn-default:disabled,
.btn-default.disabled {
    background-color: #E6E6E6;
}

.btn-default:before {
    background: #85868C;
}



.btn-primary,
.btn-primary:focus {
    background-color: #0083ae;
    color: #fff;
    border-color: #0083ae;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary.active {
    background-color: #01516B;
    color: #fff;
    border-color: #01516B;
    background-image: none;
}

.btn-primary:before {
    background: #01516B;
}

.btn-link,
.btn-link:focus {
    color: #0083ae;
    text-decoration: none;
    border-bottom: 1px solid #0083ae;
}

.btn-link:hover,
.btn-link:active,
.btn-link.active {
    color: #2f3542;
    text-decoration: none;
    border-bottom-color: #2f3542;
}


/*===================================================================================================
  Form Styles
===================================================================================================*/


/* Modal
===========================================================*/

.modal-heading,
.modal-header {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .04em;
    border-bottom: 1px solid #ddd;
}


/* Modal Success
===========================================================*/

.modal-success,
.modal-success .modal-heading,
.modal-success .modal-header {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.modal-success {
    color: #fff;
    border-bottom: 0;
}

.modal-success h4 {
    font-weight: 400;
    text-transform: none;
}



/* Section Module Padding
===========================================================*/

.h-inventory-carousel-m1 {
    padding: 50px 0;
}
.locations-m1 {
    padding: 10px 0;
}

@media (max-width: 767px) {
    .h-inventory-carousel-m1 {
        padding: 30px 0;
    }
}



/* Sections Titles
===========================================================*/

.section-heading {
    padding-bottom: 30px;
}

.section-title {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .04em;
}

.page-title-headings h2,
.section-title h2,
.section-title h1,
.section-title,
.locations-section h2,
.modal-title {
    letter-spacing: .04em;
    font-weight: 700;
}


.section-subheading {
    font-size: 16px;
    line-height: 1.3em;
    font-weight: 300;
    margin-top: 10px;
    color: #fff;
}


.section-heading .section-title-line {
    display: block;
    width: 60px;
    height: 4px;
    background-color: #fff;
    margin-top: 10px;
}


/* Sections Titles
===========================================================*/
.special {
    text-decoration: line-through;
    font-size: 16px;
}

@media screen and (max-width: 767px) {
    .page-title-headings h2,
    .page-title-headings h1 {
        padding: 15px 15px;
    }

    #termsandconditions, #privacypolicy {
        padding-top: 120px;
    }
}
